.support {
    background-color: #fff;
    margin-left: 14px;
    margin-right: 14px;
    border-radius: 4px;
    margin-top: 11px;
    .btn-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        .btn-ticket {
          text-transform: uppercase;
        }
        .btn-chart {
            margin-left: 10px;
            border: 1px solid #e6e9eb;
            color: #415164;
            background-color: #f1f5f7;
            &:hover {
            background-color: #e1e4e6;
            }
        }
    }
    .search-filter {
        border: 1px solid #e6e9eb;
        color: #415164;
        background-color: #f1f5f7;
        &:hover {
        background-color: #e1e4e6;
            }
    }
    //****CONTENT TICKET
    .content-ticket {
      // .list-tickets-summary {
      //     width: 100%;
      //     display: grid;
      //     grid-template-columns: repeat(5, calc((100% - 20px)/5));
      //     grid-column-gap: 5px; 
      //     .card {
      //       padding: 1rem;
      //       margin-bottom: 14px;
      //       cursor: pointer;
      //       border: 1px solid #dce1ef;
      //       &:hover .count {
      //         color:#004b6d;
      //       }
      //       .count {
      //         font-size: 24px;
      //         font-weight: 500;
      //         color: #008ece;
      //         margin-bottom: 1rem;
      //         margin-top: 0.5rem;
              
      //       }
      //       .title {
      //         cursor: pointer;
      //         font-size: 15px;
      //         margin-bottom: 5px;
      //       }
      //     }
      //   }
      .custom-header-ticket {
        font-weight: 600;
        font-size: 18px;
      }
      .summary-element {
        background-color: #fff;
        color: #008ece;
        box-shadow: 0 1px 15px 1px rgba(90, 90, 90, .2);
        border: 1px solid #dce1ef;
        border-radius: 4px;
        margin-bottom: 20px;
        display: grid;
        width: 100%;
        text-align: center;
        cursor: pointer;
        &:hover .count {
                 color:#004b6d;
               }
        .card {
          padding-top: 16px;
          .count {
            font-size: 24px;
            font-weight: 500;
          }
          .title {
            font-size: 13px;
          }
        }
      }
//*****Export and search******
//Export
.btn-group {
  margin-top: 30px;
  .customer-filter{
      .customer-columns {
          color: #4e75ad;
          border: 1px solid #bfcbd9;
          font-size: 11.5px;
          margin-right: 10px;
          background-color: #fff;
      }
      .btn-secondary:focus {
        background-color: #fff;
      }
      //export
      .customer-out {
          color: #4e75ad ;
          border: 1px solid #bfcbd9;
          font-size: 11.5px;
          background-color: #fff;
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          text-transform: uppercase;
      }
  }
  //bulk actions
  .btn-bulk-actions {
    color: #4e75ad;
    border-radius: 3px;
    font-size: 11.5px;
    padding: 7px;
    border: 1px solid #bfcbd9!important;
    border-left: 0 !important;
    cursor: pointer;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    font-size: 14.4px;
  }
  }
.btn-reload {
    border: 1px solid #bfcbd9!important;
    color: #4e75ad !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    font-size: 11.5px;
}

//Search
.btn-list {
  margin-bottom: 10px;
 .dataTables_filter {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  .input-group{
      .search {
          width: 90px;
          transition: width .3s ease;
          &:focus {
              width: 180px;
              border-color: #03a9f4;
          }
        }
      }
  .input-group-addon {
    display: table-cell;
    width: auto;
    background-color: #fbfdff;
    color: #97a8be;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    border: 1px solid #bfcbd9;
    border-radius: 4px;
    white-space: nowrap;
    vertical-align: middle;
    padding-top: 11px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;   
  }
 }
}
  }
  //*****Table ticket******

.custom-table {
  width: 100%;
  padding: 0 0 5px 0;
  font-size: 13px;
  //columns id
  .custom-id {
      cursor: pointer;
      color: #008ece;
      span {
        &:hover {
          color: #004b6d;
        }
      }

    }
  //column subject
  .ticket-columns-subject {
    display: flex;
    cursor: pointer;
    .info-column-subject {
      padding-left: 10px;
      padding-top: 6px;
      .ticket-table-action {
        position:absolute;
        color: #fff;
        visibility: hidden;
        padding-left: 1px;
      .view {
        color: #008ece;
        margin-right: 4px;
        border-right: black;
        &:hover {
          color: #004b6d;
        }
      }
      .edit {
        color: #008ece;
        margin-right: 4px;
        border-right: black;
        &:hover {
          color: #004b6d;
        }
      }
      .delete {
        color: red;
        &:hover {
          color: #004b6d;
        }
      }
    }
    &:hover .ticket-table-action {
      visibility: visible;
      color: #004b6d;
    }
    .custom-subject {
      color: #008ece;
      text-decoration: none;
      &:hover {
        color: #004b6d;
      }
    .custom-subject:hover {
      color: black  !important;
    }
  
    }
  }

}
//columns contact
.custom-contact-ticket {
  cursor: pointer;
  color: #008ece;
  &:hover {
      color: #004b6d;
    }
}
//column status
.custom-status-ticket {
  border: 1px solid #ff2d42;
  color: #ff2d42;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}
}
}

//******Dialog Button Bulk Actions*****
.dialog-bulk-actions {
  font-size: 13px;
  color: #2d2d2d;
  .mass-delete {
    #checkbox6 {
    margin-right: 4px;
    font-size: 17px;
    }
  }
  //select change status
  .change-status {
    margin-top: 20px;
  }
  //select
  .form-group {
    margin-bottom: 15px;
    label {
      margin-bottom: 7px;
      font-size: 13px;
      font-weight: 400;
    }
  }
  //tags
  .tags {
    i {
      margin-right: 2px;
    }
  }
  //service
  .service {
    margin-bottom: 30px;
  }
  //button dialog
  .button-list {
  display: flex;
  justify-content:flex-end;
  padding: 12px 12px 0 12px;
  .btn-close {
      border: 1px solid #e6e9eb;
      color: #415164;
      background-color: #f1f5f7;
      font-size: 13.5px;
      text-transform: uppercase;
      &:hover {
        color: #415164;
        background-color: #e1e4e6;     
      }
  }
  .btn-confirm {
      font-size: 13.5px;
      text-transform: uppercase;
  }
  }
}

//*****Component New Ticket******
.new-ticket-support {
  //Content New Ticket
  .content-new-ticket {
    background-color: #fff;
    height: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    color: #2d2d2d;
    .content-left {
      padding-right: 15px;
    }
    .content-right {
      padding-left: 15px;
    }
    .form-group {
      label {
        margin-bottom: 7px;
      }
      .form-control {
        margin-bottom: 15px;
      }
      .basic-single {
        margin-bottom: 15px;
      }
    }
    //button ticket contact
    .btn-ticket-contact {
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: 400;
      background: 0 0;
      border: 1px solid #d2d5dc;
      color: #63686f;
      i {
        margin-right: 2px;
      }
      &:hover {
        background-color: #6c7888;
        color: #fff;
      }
    }
      //Tags new ticket
    .drop-tags-ticket {
      .custom-icon-tag {
        margin-right: 4px;
      }
    }
    //select-contact-ticket
    .contact-ticket {
      .select-contact-ticket {
        .select__control {
          cursor: pointer
        }
      }
    }

    //select assign ticket
    .assign-ticket {
      .select-assign-ticket {
        .select__control {
          cursor: pointer
        }

      }
    }
    
    //select project ticket
    .select-project-ticket {
      .select__control {
      cursor: not-allowed;
      }
    }
    //Name and mail
    .name-and-mail {
      display: flex;
      .input-name-ticket {
        margin-right: 30px;
      }
      .name-disabled {
        background-color: #eef1f6;
        border-color: #d1dbe5;
        color: #8babcc;
        cursor: not-allowed;
        margin-right: 30px;
      }
      .name-no-disabled {
        background-color: #fff;
        border: 1px solid #ced4da;
        margin-right: 30px;
      }
    }
    //Priority and Service
    .priority-and-service {
      display: flex;
      .input-priority-ticket {
        margin-right: 30px; 
        .select__control {
          cursor: pointer;
          }
      }
      .input-service-ticket {
        .service-and-addon {
          display: flex;
          .select-service-ticket {
            display: table-cell;
            position: relative;
            font-size: 0;
            white-space: nowrap;
            vertical-align: middle;
            margin-bottom: 0;
            width: 100%;
            border-left: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            .select__control {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              cursor: pointer;
            }
          }
          .input-addon-service {
            opacity: 1;
            border-left: 0;
            border: 1px solid #bfcbd9;
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
            vertical-align: middle;
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #fff;
          i {
            color: #008ece;
          }
          &:hover i {
            color: black;
          }
        }
        }   
      } 
    }
    //Department and CC
    .department-and-cc {
      display: flex;
      .select-department{
        .select__control {
          cursor: pointer
        }
      }
      .custom-cc {
        margin-left: 30px;
      }
    }
  }
  //*****Body New Ticket******
//*****Header******
.body-new-ticket {
 
  margin-top: 30px;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
//Header
  .header {
    .custom-header {
      color: #2d2d2d;
    }  
    font-size: 13px;
    font-weight: 500;

  }
  //select list ticket
  .select-list-ticket {
      //select predefined reply
      margin-top: 20px;
      margin-right: 10px;
      margin-left: 10px;
    .select-predefined-reply {
      padding-right: 15px;
      .select__control {
        cursor: pointer
      }
    }
    .select-insert-knowledge-ticket {
      padding-left: 15px;
      .select__control {
        cursor: pointer
      }
    }
  }
  //CKEditor
  .form-editor-ticket {
    margin-top: 20px;
  }

}
  //*****Body New Ticket******
    //footer ticket
    .footer-ticket {
      background: #f7f9fa;
      padding: 10px;
      border-radius: 4px;
      border-top: 0;
      border: 1px solid #dce1ef;
      margin-top: 10px;
      font-weight: 400;
      font-size: 13px;
      color: #2d2d2d;
      .attachment-increase {
        padding-left: 33%;
        .custom-attachment-increase {
          display: flex;
          .form-control {
            height: 42px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .btn-increase {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          } 
        }
      }
      .attachment-decrement {
        padding-left: 33%;
        .custom-attachment-decrement {
          display: flex;
          .form-control {
            height: 42px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          } 
          .btn-decrement {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      }
  //*****Button Bottom Toolbar******
    .bottom-toolbar {
      position: fixed;
      bottom: 0;
      z-index: 2;
      background-color: #fff;
      padding: 17px;
      margin: 0;
      right: 0;
      
      .btn-toolbar-ticket {
       margin-left: 88%;
       font-size: 13.5px;
       text-transform: uppercase;
      }

    }  
//*******DIALOG ICON PLUS SERVICE*******
//dialog icon plus service

}
.content-plus-service {
  .custom-star {
    color: red;
    padding-right: 2px;
  }
  //button dialog
  .button-list {
  display: flex;
  justify-content:flex-end;
  .btn-close {
      border: 1px solid #e6e9eb;
      color: #415164;
      background-color: #f1f5f7;
      font-size: 13.5px;
      &:hover {
        color: #415164;
        background-color: #e1e4e6;     
      }
  }
  .btn-save {
      font-size: 13.5px;
  }
  }
}
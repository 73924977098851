.list-summary {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, calc((100% - 20px)/5));
  grid-column-gap: 5px;
  .card {
    padding: 1rem;
    margin-bottom: 14px;
    .count {
      font-size: 24px;
      font-weight: 500;
      color: black;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    .title {
      font-size: 15px;
      margin-bottom: 5px;
    }
    .sub-title {
      font-size: 13px;
      color: #777;
    }
  }
}

// body{
//     max-width: 1368px;
//     margin: 0 auto;
// }

.bg-white {
    background-color: #fff;
}
.box-name {
    position: relative;
    width: 100%;
    height: 100%;
    .box-action {
        display: none;
        position: absolute;
        bottom: -10px;
        font-size: 12px;
        margin-left: 1px;
        .edit {
            margin-left: 5px;
        }
        .delete {
            margin-left: 5px;
            color: #ea1010;
        }
    }
}

.box-name:hover .box-action {
    display: block;
}

//button close
//className="btn btn-close waves-effect width-sm waves-light" (btn-close)
//className="btn btn-primary waves-effect width-sm waves-light" (btn-save)
.btn-close {
    border: 1px solid #dbddde !important;
    color: #415164 !important;
    background-color: #f1f5f7 !important;
    margin-right: 8px !important;
    text-transform: uppercase;
    &:hover {
        background-color: #e6e9eb !important;
    }
}

//button export, reload
// className="btn btn-export waves-effect width-xs waves-info" (btn-export)
//className="btn btn-icon waves-effect btn-reload"(reload)
.btn-export,
.btn-reload {
    box-shadow: none !important;
    background-color: #fff !important;
    color: #4e75ad !important;
    border: 1px solid #bfcbd9 !important;
    height: 38px !important;
}
.btn-export {
    border-radius: 4px 0 0 4px !important;
    text-transform: uppercase;

}
.btn-reload {
    border-radius: 0 4px 4px 0 !important;
}

//button search
//className="btn btn-icon waves-effect btn-lighten-secondary btn-search"
.btn-search {
    border-radius: 4px 0 0 4px !important;
    background-color: #fff !important;
}

//input search
//className="form-control input-search"
.input-search {
    width: 90px !important;
    transition: width 0.3s ease !important;
    border-radius: 0 4px 4px 0 !important;
    &:focus {
        width: 180px !important;
        border-color: #03a9f4 !important;
    }
}

//button edit
//className="btn btn-icon waves-effect btn-secondary btn-edit"
//className="btn btn-icon waves-effect waves-light btn-danger"(btn-delete)
.btn-edit {
    background-color: #f1f5f7 !important;
    color: #333 !important;
    padding: 8px 12px !important;
    border: none !important;
    margin-right: 5px !important;
    &:hover {
        background-color: #e1e4e6 !important;
    }
}

.dropdown-item {
    cursor: pointer;
}
.w300 {
    width: 300px;
    transform: unset !important;
}
// text validate
.text-validate{
    margin-top: 10px;
    margin-bottom: unset;
    color: #f72705;
}
.validate-input::before {
    content: '*';
    color: #ff0000;
}
.font-size-16{
    font-size: 16px;
}
.dialog-header{
    background: linear-gradient(to right,#226faa 0,#2989d8 37%,#72c0d3 100%);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: transparent;
}
.dialog-text{
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 17px;
    line-height: 19.8px;
    border-radius: 6px;
    padding: 18px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: transparent;
    .custom-title-dialog {
        i {
            margin-right: 4px;
        }
    }
}

.input-require {
    &::before {
        content: "*";
        color: red;
        margin-right: 5px;
    }
}